body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.admission-container {
  clip-path: polygon(0 0, 100% 0, 100% 69%, 24% 100%, 0 73%);
}

@media (max-width: 1024px) {
  .admission-container {
    clip-path: polygon(0 0, 100% 0, 100% 85%, 24% 100%, 0 85%);
  }
}

@media (max-width: 768px) {
  .admission-container {
    clip-path: polygon(0 0, 100% 0, 100% 85%, 24% 100%, 0 85%);
  }
}

@media (max-width: 480px) {
  .admission-container {
    clip-path: polygon(0 0, 100% 0, 100% 90%, 24% 100%, 0 90%);
  }
}

@keyframes float {

  0% {
    transform: translateY(0) rotate(0deg)
  }

  100% {
    transform: translateY(0) rotate(60deg);
  }

  50% {
    transform: translateY(-15px) rotate(30deg);
  }
}

.animate-float {
  animation: float 0.8s ease-in-out infinite;
}

.delay-1000 {
  animation-delay: 240ms;
}

.delay-2000 {
  animation-delay: 490ms;
}

.delay-3000 {
  animation-delay: 740ms;
}

.container {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

@media (min-width: 1536px) {
  .container {
    max-width: 1400px;
  }
}


 .loader {
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 10px;
  margin-top: 50px;
}

 .loader img {
  width: 50px;
  height: 50px;
  animation: bounceRotate 1.2s infinite ease-in-out;
}

  @keyframes bounceRotate {
  0% {
    transform: translateY(0) rotate(0deg);
  }

  50% {
    transform: translateY(-30px) rotate(30deg);
  }

  100% {
    transform: translateY(0) rotate(60deg);
  }
}

.loader img:nth-child(1) {
  animation-delay: 0s;
}

.loader img:nth-child(2) {
  animation-delay: 0.08s;
}

.loader img:nth-child(3) {
  animation-delay: 0.16s;
}

.loader img:nth-child(4) {
  animation-delay: 0.24s;
}

.leading-tight {
  line-height: 1.1;
}